import { useMenus } from "docz"
import React from "react"
import logo from "src/images/logo-h@4x.png"
import MainHeader from "src/components/mainheader"
import MenuHeader from "src/components/menuheader"

export const Header = ({ hiddenMenu }) => {
  const docs = useMenus()

  return (
    <div>
      <MainHeader logo={logo} showHeader={100} />
      {!hiddenMenu && <MenuHeader docs={docs} />}
    </div>
  )
}
