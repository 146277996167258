import React, { useState, useEffect } from "react"
import { Navbar, Nav } from "react-bootstrap"
import { Link, useCurrentDoc } from "docz"

const MainHeader = ({ logo, showHeader }) => {
  const [show, setShow] = useState(showHeader ? true : false)
  const currentDoc = useCurrentDoc()

  const handleScroll = () => {
    if (window.pageYOffset < showHeader) {
      if (!show) setShow(true)
    } else {
      if (show) setShow(false)
    }
  }

  useEffect(() => {
    if (showHeader && currentDoc.route !== "/") {
      window.addEventListener(`scroll`, handleScroll)
      return () => window.removeEventListener(`scroll`, handleScroll)
    }
  })

  return (
    <>
      {show && (
        <Navbar
          className="flex-column flex-sm-row fixed-top"
          style={{ backgroundColor: "#f8f9fa" }}
        >
          <Navbar.Brand href="https://teamcoach.es/">
            <img
              src={logo}
              style={{
                marginBottom: 15,
                marginTop: 15,
                marginLeft: 10,
                minWidth: 150,
              }}
              className="img-responsive"
              width={233}
              alt="teamcoaches logo"
            />
          </Navbar.Brand>
          <Nav className="ml-md-auto">
            <Nav.Link as={Link} className="text-secondary" to="/">
              Documentation
            </Nav.Link>
          </Nav>
        </Navbar>
      )}
    </>
  )
}
export default MainHeader
