import { Link, useCurrentDoc } from "docz"
import React, { useEffect, useState } from "react"
import { Nav, Navbar } from "react-bootstrap"
import Search from "../gatsby-theme-docz/components/NavSearch"
import "./menuheader.css"

const MenuHeader = ({ docs }) => {
  const [flag, setFlag] = useState("80px")
  const currentDoc = useCurrentDoc()
  docs.sort(function(a, b) {
    if (a.name > b.name) {
      return 1
    }
    if (a.name < b.name) {
      return -1
    }
    return 0
  })

  const handleScroll = () => {
    if (window.pageYOffset > 99) {
      setFlag("0px")
    } else {
      setFlag("80px")
    }
  }

  useEffect(() => {
    if (flag && currentDoc.route !== "/") {
      window.addEventListener(`scroll`, handleScroll)
      return () => window.removeEventListener(`scroll`, handleScroll)
    }
  }, [flag, currentDoc.route])

  return (
    <>
      <Navbar
        className="pl-md-6 navbar-light fixed-top"
        expand="md"
        variant="doc"
        style={{
          marginTop: `${flag}`,
          backgroundColor: "#f8f9fa",
          border: "none",
        }}
      >
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse
          className="justify-content-between mr-4"
          id="responsive-navbar-nav"
        >
          <Nav>
            {currentDoc.route !== "/" &&
              docs.map(
                (doc, index) =>
                  !doc.hidden && (
                    <Nav.Link
                      key={index}
                      activeClassName="active"
                      as={Link}
                      to={`/${doc.name.toLowerCase()}`}
                    >
                      {doc.name}
                    </Nav.Link>
                  )
              )}
          </Nav>
          <Nav>
            <Search />
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  )
}

export default MenuHeader
