import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Link, useDocs, useMenus } from "docz"
import SearchIcon from "react-feather/dist/icons/search"
import { FormControl, Col } from "react-bootstrap"

const Search = () => {
  const [search, setsearch] = useState("")
  const [role, setRole] = useState([])
  const [name, setName] = useState([])
  const [tags, setTags] = useState([])
  const menus = useMenus()
  const docs = useDocs()

  const data = useStaticQuery(graphql`
    query {
      allMdx {
        nodes {
          frontmatter {
            keywords
            menu
            route
          }
        }
      }
    }
  `)
  const keywords = data.allMdx.nodes

  const handleOnChange = e => {
    setsearch(e.target.value)
    if (search.length > 2) {
      filterByRole(search)
      filterByName(search)
      filterByTag(search)
    }
  }

  const filterByRole = search => {
    menus.filter(item => {
      if (item.name.toLowerCase().indexOf(search) >= 0) {
        setRole([item])
      }
      return
    })
  }

  const filterByName = search => {
    const result = []
    docs.map(item => {
      if (item.name.toLowerCase().indexOf(search) >= 0) {
        result.push(item)
      }
    })
    return setName(result)
  }

  const filterByTag = search => {
    const result = []
    keywords.map(item => {
      item.frontmatter.keywords &&
        item.frontmatter.keywords.map(tag => {
          if (tag.indexOf(search) >= 0) {
            result.push([item.frontmatter.menu, item.frontmatter.route, tag])
          }
        })
    })
    return setTags(result)
  }

  useEffect(() => {
    if (!search) {
      setRole([])
      setName([])
      setTags([])
    }
  }, [search])

  return (
    <Col xs={12} className="m-1">
      <div style={{ display: "inline-flex" }}>
        <SearchIcon size={20} className="mt-2 mr-2" />
        <FormControl
          type="text"
          placeholder="Type to search"
          style={{
            fontFamily: "Source Sans Pro', sans-serif",
            minWidth: "250px",
            border: "none",
          }}
          value={search}
          onChange={handleOnChange}
        />
      </div>
      {search.length > 2 && (
        <div
          style={{
            position: "absolute",
            zIndex: "2",
            minWidth: "260px",
            border: "1px solid #F5F6F7",
            marginTop: "5px",
            marginLeft: "20px",
            backgroundColor: "#FFF",
          }}
        >
          {role[0]?.menu &&
            role[0].menu.map(
              d =>
                !d.hidden && (
                  <div className="ml-2" key={d.route}>
                    <Link
                      activeClassName="active"
                      to={
                        d.route === `/${d.menu.toLowerCase()}`
                          ? `/${d.menu.toLowerCase()}`
                          : `/${d.menu.toLowerCase()}-${d.name.toLowerCase()}`
                      }
                    >
                      {d.name} - ({d.menu})
                    </Link>
                  </div>
                )
            )}
          {name[0]?.name &&
            name.map(
              d =>
                !d.hidden && (
                  <div className="ml-2" key={d.route}>
                    <Link activeClassName="active" to={d.route}>
                      {d.name} - ({d.menu})
                    </Link>
                  </div>
                )
            )}
          {tags[0] &&
            tags.map(d => (
              <div className="ml-2" key={d[1]}>
                <Link activeClassName="active" to={d[1]}>
                  {d[2]} - ({d[0]})
                </Link>
              </div>
            ))}
        </div>
      )}
    </Col>
  )
}

export default Search
